import './config';
import './index.scss';
import ReactDOM from 'react-dom';
import glue from '@smartfire/glue-ui';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

const resources = undefined;

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
        nsSeparator: false,
        returnEmptyString: false,
    });

dayjs.extend(localizedFormat);

const websites = {
    LMI: () => import(/* webpackChunkName: "lamoto-italienne" */ './lamoto-italienne'),
    LMA: () => import(/* webpackChunkName: "lamoto-allemande" */ './lamoto-allemande'),
    FRM: () => import(/* webpackChunkName: "fert-moto" */ './fert-moto'),
    ADMIN: () => import(/* webpackChunkName: "admin-ui" */ './admin'),
};

const origin = window.location.host;

(async function () {
    try {
        const data = await (await fetch('/websites.json')).json();
        const valid = Object.keys(websites).filter((key) => {
            return data[key] === origin;
        });
        if (valid.length > 0) {
            let website = valid[0];
            await glue.api.interceptors.request.use((config) => {
                if (website !== 'ADMIN') {
                    config.params = { ...config.params, website: website };
                }
                return config;
            });
            //@ts-ignore
            await websites[website]();
        } else {
            ReactDOM.render(
                <div>404 Not Found - Please select one website from list</div>,
                document.getElementById('root'),
            );
        }
    } catch (e) {
        console.error(e);
        console.error('unable to load website');
    }
})();
